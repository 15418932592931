import React, { FC } from 'react';
import Col from 'react-bootstrap/Col';

import DropdownRegion from 'components/DropdownRegion';
import { IFooterTopRegionsComponent } from './models';

const FooterTopRegions: FC<IFooterTopRegionsComponent> = ({ regionDropDown, titleRegions }) => {
  const { section, selectedCountryCode } = regionDropDown;

  return (
    <Col className="dt-footer__top-col" sm={6} lg={4}>
      <strong className="dt-footer__top-title">{titleRegions || 'Regions'}</strong>
      <DropdownRegion data={section} selectedCountryCode={selectedCountryCode} />
    </Col>
  );
};

export default FooterTopRegions;
